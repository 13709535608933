/**
 * @name: 全局插件
 * @author: itmobai
 * @date: 2023-08-04 10:56
 * @description：全局插件
 * @update: 2023-08-04 10:56
 */
import store from "@/store"

import permission from "./directive/permission"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import CRUD from "m-ui-crud"
import 'm-ui-crud/lib/m-ui.css'
import '@/assets/styles/index.scss'

import "@/mui"

// 分页组件
import Pagination from "@/components/Pagination/index.vue"

// 全局插件
export default {
  install(Vue: any) {
    Vue.config.productionTip = false
    // 注入权限
    Vue.directive("permission", permission)

    Vue.use(ElementUI, { size: 'small' });
    // Vue.use(CRUD, {
    //   dicHeaders: { Authorization: `Bearer ${store.getters.token || ''}`}
    // })
    Vue.use(CRUD)
    Vue.prototype.$MCRUD = Object.assign(CRUD.config, {dicHeaders: { Authorization: `Bearer ${store.getters.token || ''}`}})
    Vue.component("Pagination", Pagination)
  }
}
